import { ApiDataType } from '../api'
import { useApiData } from './useApiData'
import { useSelf } from './useSelf'

export const useBrand = () => {
  const { self } = useSelf()
  const allBrands = useApiData<ApiDataType.Brands>({
    apiKey: 'brands',
    once: true,
  })

  const selfBrand = allBrands?.find(brand => brand.id === self?.brand)

  return { selfBrand, allBrands }
}
