import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMountedState, useUnmount } from 'react-use'
import { useApiCreate } from './useApiCreate'
import { useApiUpdate } from './useApiUpdate'
import { useCharKey } from './useCharKey'
import { usePushSnack } from './usePushSnack'
import { useRouter } from './useRouter'

type Args = {
  apiCreate: ReturnType<typeof useApiCreate>
  apiUpdate: ReturnType<typeof useApiUpdate>
  pathnameOnCreate: string
}

export const useApiMutation = ({
  apiCreate,
  apiUpdate,
  pathnameOnCreate,
}: Args) => {
  const [duplicate, setDuplicate] = useState(false)
  const [refreshOnUnmount, setRefreshOnUnmount] = useState(false)
  const { t } = useTranslation()
  const getIsMounted = useMountedState()
  const { replace } = useRouter()
  const pushSnack = usePushSnack()
  const { generateCharKey } = useCharKey()

  const apiMutate = useCallback(
    async ({
      body,
      create,
      edit,
    }: {
      body: Partial<{ name: string; id: number }> & unknown
      create?: boolean
      edit?: boolean
    }) => {
      if (create || duplicate) {
        body.name = duplicate ? body.name?.concat(' - Copy') : body.name
        duplicate && delete body.id

        const res = await apiCreate<{ id: number }>(body)

        pushSnack(
          {
            message: t(
              duplicate ? 'formik.ok.duplicated' : 'formik.ok.created',
              {
                name: body.name,
              }
            ),
          },
          { severity: 'success' }
        )

        if (getIsMounted()) {
          replace({
            pathname: `${pathnameOnCreate}/${res.data.id}`,
          })
          setRefreshOnUnmount(true)
          setDuplicate(false)
        } else {
          generateCharKey()
        }
      }

      if (edit && !duplicate) {
        await apiUpdate(body)

        pushSnack(
          { message: t('formik.ok.updated', { name: body.name }) },
          { severity: 'success' }
        )

        if (getIsMounted()) {
          setRefreshOnUnmount(true)
        } else {
          generateCharKey()
        }
      }
    },
    [
      apiCreate,
      apiUpdate,
      duplicate,
      generateCharKey,
      getIsMounted,
      pathnameOnCreate,
      pushSnack,
      replace,
      t,
    ]
  )

  const duplicateOnMutate = useCallback(() => {
    setDuplicate(true)
  }, [setDuplicate])

  useUnmount(() => {
    refreshOnUnmount && generateCharKey()
  })

  return { apiMutate, duplicateOnMutate }
}
