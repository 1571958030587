import Box from '@material-ui/core/Box'
import { FormikProvider, useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt, useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import * as yup from 'yup'
import DetailedContent from '../../components/DetailedContent'
import TextField from '../../components/TextField'
import Title from '../../components/Title'
import { ApiDataType } from '../../core/api'
import { useApiCreate } from '../../core/hooks/useApiCreate'
import { useApiData } from '../../core/hooks/useApiData'
import { useApiMutation } from '../../core/hooks/useApiMutation'
import { useApiUpdate } from '../../core/hooks/useApiUpdate'
import { useHasFieldErrors } from '../../core/hooks/useHasFieldsError'
import ComponentActions from '../ComponentActions'
import { useStyles } from './styles'

type Props = Partial<{
  create: boolean
  edit: boolean
}>

const SpacerDetailView: FC<Props> = ({ create, edit }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const apiUpdate = useApiUpdate({ apiKey: 'components', id: parseInt(id) })
  const apiCreate = useApiCreate({
    apiKey: 'components',
  })
  const { apiMutate, duplicateOnMutate } = useApiMutation({
    apiCreate,
    apiUpdate,
    pathnameOnCreate: '/components/spacer',
  })
  const component = useApiData<ApiDataType.Component.Spacer>({
    apiKey: 'components',
    id,
    avoid: create,
  })

  const validationSchema = yup.object({
    name: yup.string().required(t('formik.errors.required')),
    options: yup.object({
      desktopHeight: yup
        .number()
        .min(1, t('formik.errors.greater_than_0'))
        .required(t('formik.errors.required')),
      tabletHeight: yup
        .number()
        .min(1, t('formik.errors.greater_than_0'))
        .required(t('formik.errors.required')),
      mobileHeight: yup
        .number()
        .min(1, t('formik.errors.greater_than_0'))
        .required(t('formik.errors.required')),
    }),
  })

  type FormFields = yup.TypeOf<typeof validationSchema>

  const initialValues = component || {
    name: '',
    options: {
      desktopHeight: 80,
      tabletHeight: 40,
      mobileHeight: 20,
    },
  }

  const formik = useFormik<FormFields>({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        id: component?.id,
        template: component?.template,
        slug: 'spacer',
        ...values,
      }

      await apiMutate({ body, create, edit })

      actions.resetForm({ values })
    },
  })

  useUpdateEffect(() => {
    formik.resetForm({ values: initialValues })
  }, [component])

  const hasFieldErrors = useHasFieldErrors(formik)

  const hasOverviewError = hasFieldErrors(['name'])
  const hasOptionsError = hasFieldErrors(['options'])

  const isDisabled = useMemo(
    () => (edit && !component) || formik.isSubmitting,
    [edit, component, formik.isSubmitting]
  )

  const testAttrNamespace = useMemo(
    () => (create ? 'text-create' : 'text-edit'),
    [create]
  )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Prompt
        when={!isDisabled && formik.dirty}
        message={_ => t('dialogs.alerts.unsaved_modifications') as string}
      />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Title
            testAttrNamespace={testAttrNamespace}
            title={
              create
                ? formik.values.name || t('views.spacer.create.title')
                : formik.values.name || component?.name || '...'
            }
            subtitle={
              create
                ? t('views.spacer.create.subtitle')
                : t('views.spacer.edit.subtitle')
            }
            actionRender={
              <ComponentActions
                showMore={edit}
                isDirty={formik.dirty}
                isDisabled={isDisabled}
                isSubmitting={formik.isSubmitting}
                onDuplicate={() => {
                  duplicateOnMutate()
                  formik.submitForm()
                }}
              />
            }
            withBackButton
          />
          <DetailedContent
            hints={[
              'Fill all tabs to save a new spacer in your component library.',
              'You can edit fields after saving if needed.',
            ]}
            tabs={[
              {
                label: t('common.overview'),
                subtitle: t('common.general_information'),
                hasError: hasOverviewError,
                render: (
                  <Box>
                    <TextField
                      disabled={isDisabled}
                      error={formik.errors.name}
                      label={t('common.name')}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      touched={formik.touched.name}
                      value={formik.values.name}
                      required
                    />
                  </Box>
                ),
              },
              {
                label: t('common.content'),
                subtitle: t('common.component_information'),
                disabled: true,
                render: null,
              },
              {
                label: t('common.options'),
                subtitle: t('common.component_configuration'),
                hasError: hasOptionsError,
                render: (
                  <Box>
                    <TextField
                      label={t('common.desktop_height')}
                      name="options.desktopHeight"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.options.desktopHeight}
                      type="number"
                      error={formik.errors.options?.desktopHeight}
                      touched={formik.touched.options?.desktopHeight}
                      required
                    />
                    <TextField
                      label={t('common.tablet_height')}
                      name="options.tabletHeight"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.options.tabletHeight}
                      type="number"
                      error={formik.errors.options?.tabletHeight}
                      touched={formik.touched.options?.tabletHeight}
                      required
                    />
                    <TextField
                      label={t('common.mobile_height')}
                      name="options.mobileHeight"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.options.mobileHeight}
                      type="number"
                      error={formik.errors.options?.mobileHeight}
                      touched={formik.touched.options?.mobileHeight}
                      required
                    />
                  </Box>
                ),
              },
            ]}
          />
        </form>
      </FormikProvider>
    </Box>
  )
}

export default SpacerDetailView
