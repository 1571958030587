import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))
