import { ConnectedRouter } from 'connected-react-router'
import { FC, lazy, LazyExoticComponent } from 'react'
import { BrowserRouter, Redirect } from 'react-router-dom'
import { useAuth } from '../core/hooks/useAuth'
import { history } from '../core/store'
import DrawerFabLayout from '../layouts/DrawerFabLayout'
import DrawerLayout from '../layouts/DrawerLayout'
import * as JLC from '../views/jlc'
import LoginView from '../views/LoginView'
import SpacerDetailView from '../views/SpacerDetailView'
import RouteWrapper from './RouteWrapper'
import SwitchWrapper from './SwitchWrapper'

const DashboardView = lazy(() => import('../views/DashboardView'))
const ShowcaseIndexView = lazy(() => import('../views/ShowcaseIndexView'))
const ShowcaseDetailView = lazy(() => import('../views/ShowcaseDetailView'))
const CompositionIndexView = lazy(() => import('../views/CompositionIndexView'))
const CompositionDetailView = lazy(
  () => import('../views/CompositionDetailView')
)
const ComponentIndexView = lazy(() => import('../views/ComponentIndexView'))
const TextDetailView = lazy(() => import('../views/TextDetailView'))
const ImageDetailView = lazy(() => import('../views/ImageDetailView'))
const VideoDetailView = lazy(() => import('../views/VideoDetailView'))
const BannerDetailView = lazy(() => import('../views/BannerDetailView'))
const CatalogueDetailView = lazy(() => import('../views/CatalogueDetailView'))
const LinkDetailView = lazy(() => import('../views/LinkDetailView'))
const LogoDetailView = lazy(() => import('../views/LogoDetailView'))
const ProfileView = lazy(() => import('../views/ProfileView'))
const ContactFormDetailView = lazy(
  () => import('../views/ContactFormDetailView')
)
const SocialNetworksDetailView = lazy(
  () => import('../views/SocialNetworksDetailView')
)
const MediaPlayerDetailView = lazy(
  () => import('../views/MediaPlayerDetailView')
)
const GenderFilterDetailView = lazy(
  () => import('../views/GenderFilterDetailView')
)
const CollectionFilterDetailView = lazy(
  () => import('../views/CollectionFilterDetailView')
)
const CollectionMenuDetailView = lazy(
  () => import('../views/CollectionMenuDetailView')
)
const MediaAndTextDetailView = lazy(
  () => import('../views/MediaAndTextDetailView')
)
const TitleParagraphDetailView = lazy(
  () => import('../views/TitleParagraphDetailView')
)
const CarouselDetailView = lazy(() => import('../views/CarouselDetailView'))

type RenderDetailViewArgs = {
  path: string
  Component:
    | LazyExoticComponent<
        FC<
          Partial<{
            create: boolean
            edit: boolean
          }>
        >
      >
    | FC<
        Partial<{
          create: boolean
          edit: boolean
        }>
      >
}

const Router: FC = () => {
  const { isApproved } = useAuth()

  const renderDetailView = ({ path, Component }: RenderDetailViewArgs) => {
    return [
      <RouteWrapper path={`${path}/create`} exact>
        <Component create />
      </RouteWrapper>,
      <RouteWrapper path={`${path}/:id`} exact>
        <Component edit />
      </RouteWrapper>,
    ]
  }

  return isApproved ? (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <DrawerLayout />
        <DrawerFabLayout />
        <SwitchWrapper>
          <RouteWrapper path="/virtual-showcases" exact>
            <ShowcaseIndexView />
          </RouteWrapper>
          <RouteWrapper path="/compositions" exact>
            <CompositionIndexView />
          </RouteWrapper>
          <RouteWrapper path="/components" exact>
            <ComponentIndexView />
          </RouteWrapper>
          {[
            {
              path: '/virtual-showcases',
              Component: ShowcaseDetailView,
            },
            {
              path: '/compositions',
              Component: CompositionDetailView,
            },
            {
              path: '/components/image',
              Component: ImageDetailView,
            },
            {
              path: '/components/link',
              Component: LinkDetailView,
            },
            {
              path: '/components/text',
              Component: TextDetailView,
            },
            {
              path: '/components/video',
              Component: VideoDetailView,
            },
            {
              path: '/components/banner',
              Component: BannerDetailView,
            },
            {
              path: '/components/catalogue',
              Component: CatalogueDetailView,
            },
            {
              path: '/components/title-paragraph',
              Component: TitleParagraphDetailView,
            },
            {
              path: '/components/media-and-text',
              Component: MediaAndTextDetailView,
            },
            {
              path: '/components/logo',
              Component: LogoDetailView,
            },
            {
              path: '/components/gender-filter',
              Component: GenderFilterDetailView,
            },
            {
              path: '/components/collection-filter',
              Component: CollectionFilterDetailView,
            },
            {
              path: '/components/media-player',
              Component: MediaPlayerDetailView,
            },
            {
              path: '/components/contact-form',
              Component: ContactFormDetailView,
            },
            {
              path: '/components/social-networks',
              Component: SocialNetworksDetailView,
            },
            {
              path: '/components/collection-menu',
              Component: CollectionMenuDetailView,
            },
            {
              path: '/components/carousel',
              Component: CarouselDetailView,
            },
            {
              path: '/components/spacer',
              Component: SpacerDetailView,
            },
            {
              path: '/components/jlc-hero-banner',
              Component: JLC.HeroBannerDetailView,
            },
            {
              path: '/components/jlc-text-block',
              Component: JLC.TextBlockDetailView,
            },
            {
              path: '/components/jlc-fifty-fifty',
              Component: JLC.FiftyFiftyDetailView,
            },
            {
              path: '/components/jlc-collection-carousel',
              Component: JLC.CollectionCarouselDetailView,
            },
            {
              path: '/components/jlc-product-catalogue',
              Component: JLC.ProductCatalogueDetailView,
            },
            {
              path: '/components/jlc-social-feed',
              Component: JLC.SocialFeedDetailView,
            },
            {
              path: '/components/jlc-high-contrast-switch',
              Component: JLC.HighContrastSwitchView,
            },
          ].map(detail => renderDetailView(detail))}
          <RouteWrapper path="/profile" exact>
            <ProfileView />
          </RouteWrapper>
          <RouteWrapper path="/" exact>
            <DashboardView />
          </RouteWrapper>
          <Redirect to="/" />
        </SwitchWrapper>
      </ConnectedRouter>
    </BrowserRouter>
  ) : (
    <LoginView />
  )
}

export default Router
