export default function Svg() {
  return (
    <svg height={32} viewBox="0 0 512 512" width={32}>
      <path
        d="M241 512v-60.333h-7.583c-33.176 0-60.167-26.991-60.167-60.167s26.991-60.167 60.167-60.167H241V271h-75.333c-8.284 0-15-6.716-15-15v-22.583c0-16.634-13.533-30.167-30.167-30.167s-30.167 13.533-30.167 30.167V256c0 8.284-6.716 15-15 15H0v210.833C0 498.467 13.533 512 30.167 512z"
        fill="currentColor"
      />
      <path
        d="M481.833 512C498.467 512 512 498.467 512 481.833V271h-60.333v7.583c0 33.176-26.991 60.167-60.167 60.167s-60.167-26.991-60.167-60.167V271H271v75.333c0 8.284-6.716 15-15 15h-22.583c-16.634 0-30.167 13.533-30.167 30.167s13.533 30.167 30.167 30.167H256c8.284 0 15 6.716 15 15V512z"
        fill="currentColor"
      />
      <path
        d="M391.5 308.75c16.634 0 30.167-13.533 30.167-30.167V256c0-8.284 6.716-15 15-15H512V30.167C512 13.533 498.467 0 481.833 0H271v60.333h7.583c33.176 0 60.167 26.991 60.167 60.167s-26.991 60.167-60.167 60.167H271V241h75.333c8.284 0 15 6.716 15 15v22.583c0 16.634 13.533 30.167 30.167 30.167z"
        fill="currentColor"
      />
      <path
        d="M30.167 0C13.533 0 0 13.533 0 30.167V241h60.333v-7.583c0-33.176 26.991-60.167 60.167-60.167s60.167 26.991 60.167 60.167V241H241v-75.333c0-8.284 6.716-15 15-15h22.583c16.634 0 30.167-13.533 30.167-30.167s-13.533-30.167-30.167-30.167H256c-8.284 0-15-6.716-15-15V0z"
        fill="currentColor"
      />
    </svg>
  )
}
