import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Prompt, useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import * as yup from 'yup'
import CollapsibleOption from '../../../components/CollapsibleOption'
import DetailedContent from '../../../components/DetailedContent'
import FieldGroupTitle from '../../../components/FieldGroupTitle'
import OrderableCard from '../../../components/OrderableCard'
import SelectField from '../../../components/SelectField'
import TextField from '../../../components/TextField'
import Title from '../../../components/Title'
import { ApiDataType } from '../../../core/api'
import { useApiCreate } from '../../../core/hooks/useApiCreate'
import { useApiData } from '../../../core/hooks/useApiData'
import { useApiMutation } from '../../../core/hooks/useApiMutation'
import { useApiUpdate } from '../../../core/hooks/useApiUpdate'
import { useHasFieldErrors } from '../../../core/hooks/useHasFieldsError'
import { idNameObjToOption } from '../../../core/tools/idNameObjToOption'
import ComponentActions from '../../ComponentActions'
import { useStyles } from './styles'

type Props = Partial<{
  create: boolean
  edit: boolean
}>

const ProductCatalogueDetailView: FC<Props> = ({ create, edit }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const apiUpdate = useApiUpdate({ apiKey: 'components', id: parseInt(id) })
  const apiCreate = useApiCreate({
    apiKey: 'components',
  })
  const { apiMutate, duplicateOnMutate } = useApiMutation({
    apiCreate,
    apiUpdate,
    pathnameOnCreate: '/components/jlc-product-catalogue',
  })
  const component = useApiData<ApiDataType.Component.JLC.ProductCatalogue>({
    apiKey: 'components',
    id,
    avoid: create,
  })
  const categoryValues = useApiData<ApiDataType.CategoryValues>({
    apiKey: 'catalogue',
    once: true,
  })
  const catalogueAttributes = useApiData<ApiDataType.CatalogueAttributes>({
    apiKey: 'catalogueAttributes',
    once: true,
  })
  const textComponents = useApiData<ApiDataType.Component.Text[]>({
    apiKey: 'components',
    params: { slug: 'text' },
  })
  const imageComponents = useApiData<ApiDataType.Component.Image[]>({
    apiKey: 'components',
    params: { slug: 'image' },
  })

  const validationSchema = yup.object({
    name: yup.string().required(t('formik.errors.required')),
    content: yup.object({
      assortment: yup
        .number()
        .nullable()
        .defined()
        .default(null)
        .required(t('formik.errors.required')),
      classification: yup.number().nullable().default(null),
      collection: yup
        .number()
        .nullable()
        .default(null)
        .required(t('formik.errors.required')),
      image1: yup
        .number()
        .nullable()
        .default(null)
        .required(t('formik.errors.required')),
      image2: yup
        .number()
        .nullable()
        .default(null)
        .required(t('formik.errors.required')),
    }),
    options: yup.object({
      filters: yup.array(yup.string().default('')).default([]),
    }),
  })

  type FormFields = yup.TypeOf<typeof validationSchema>

  const initialValues = component || {
    name: '',
    content: {
      assortment: null,
      classification: null,
      collection: null,
      image1: null,
      image2: null,
    },
    options: {
      filters: [],
    },
  }

  const formik = useFormik<FormFields>({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        id: component?.id,
        template: component?.template,
        slug: 'jlc-product-catalogue',
        ...values,
      }

      await apiMutate({ body, create, edit })

      actions.resetForm({ values })
    },
  })

  useUpdateEffect(() => {
    formik.resetForm({ values: initialValues })
  }, [component])

  const hasFieldErrors = useHasFieldErrors(formik)

  const hasOverviewError = hasFieldErrors(['name'])
  const hasContentError = hasFieldErrors(['content.categoryValue'])

  const isDisabled = useMemo(
    () => (edit && !component) || formik.isSubmitting,
    [edit, component, formik.isSubmitting]
  )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Prompt
        when={!isDisabled && formik.dirty}
        message={_ => t('dialogs.alerts.unsaved_modifications') as string}
      />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Title
            title={
              create
                ? formik.values.name ||
                  t('views.product_catalogue.create.title')
                : formik.values.name || component?.name || '...'
            }
            subtitle={
              create
                ? t('views.product_catalogue.create.subtitle')
                : t('views.product_catalogue.edit.subtitle')
            }
            actionRender={
              <ComponentActions
                showMore={edit}
                isDirty={formik.dirty}
                isDisabled={isDisabled}
                isSubmitting={formik.isSubmitting}
                onDuplicate={() => {
                  duplicateOnMutate()
                  formik.submitForm()
                }}
              />
            }
            withBackButton
          />
          <DetailedContent
            hints={[
              'Fill all tabs to save a new catalogue component in your library.',
              'As most of the content is coming from the PIM or OCP, content tab only enables you to browse and select a products assortment and a classification.',
              'The classification field allows you to choose which collection to display in the catalogue.',
              'You can edit fields after saving if needed.',
            ]}
            tabs={[
              {
                label: t('common.overview'),
                subtitle: t('common.general_information'),
                hasError: hasOverviewError,
                render: (
                  <Box>
                    <TextField
                      disabled={isDisabled}
                      error={formik.errors.name}
                      label={t('common.name')}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      touched={formik.touched.name}
                      value={formik.values.name}
                      required
                    />
                  </Box>
                ),
              },
              {
                label: t('common.content'),
                subtitle: t('common.component_information'),
                hasError: hasContentError,
                render: (
                  <Box>
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.assortment')}
                      error={formik.errors.content?.assortment}
                      name="content.assortment"
                      options={categoryValues?.assortments?.map(a =>
                        idNameObjToOption(a)
                      )}
                      onBlur={formik.handleBlur}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.assortment}
                      value={formik.values.content.assortment}
                      required
                    />
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.classification')}
                      error={formik.errors.content?.classification}
                      name="content.classification"
                      options={categoryValues?.classifications?.map(a =>
                        idNameObjToOption(a)
                      )}
                      onBlur={formik.handleBlur}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.classification}
                      value={formik.values.content.classification}
                    />
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.collection')}
                      error={formik.errors.content?.collection}
                      name="content.collection"
                      options={textComponents?.map(c => idNameObjToOption(c))}
                      onBlur={formik.handleBlur}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.collection}
                      value={formik.values.content.collection}
                      required
                    />
                    <FieldGroupTitle>
                      {t('common.collection_description')}
                    </FieldGroupTitle>
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.left_image') + ' (4:5)'}
                      error={formik.errors.content?.image1}
                      name="content.image1"
                      options={imageComponents?.map(c => idNameObjToOption(c))}
                      onBlur={formik.handleBlur}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.image1}
                      value={formik.values.content.image1}
                      required
                    />
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.right_image') + ' (4:5)'}
                      error={formik.errors.content?.image2}
                      name="content.image2"
                      options={imageComponents?.map(c => idNameObjToOption(c))}
                      onBlur={formik.handleBlur}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.image2}
                      value={formik.values.content.image2}
                      required
                    />
                  </Box>
                ),
              },
              {
                label: t('common.options'),
                subtitle: t('common.options'),
                render: (
                  <Box>
                    <CollapsibleOption title={t('common.catalogue_filters')}>
                      <FieldArray
                        name="options.filters"
                        render={arrayHelpers => (
                          <DragDropContext
                            onDragEnd={({ source, destination }) => {
                              if (source.index === destination?.index) return

                              destination?.index !== undefined &&
                                arrayHelpers.move(
                                  source.index,
                                  destination.index
                                )
                            }}
                          >
                            <Droppable droppableId="filters">
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {!formik.values.options.filters.length && (
                                    <Typography
                                      color="textSecondary"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {t('common.no_filter_selected')}
                                    </Typography>
                                  )}
                                  {formik.values.options.filters.map(
                                    (filterKey, index) => (
                                      <OrderableCard
                                        key={filterKey}
                                        charKey={filterKey}
                                        index={index}
                                        render={
                                          <Box
                                            alignItems="center"
                                            display="flex"
                                            justifyContent="space-between"
                                            width={1}
                                          >
                                            <Typography>
                                              {
                                                catalogueAttributes?.filters.find(
                                                  f => f.key === filterKey
                                                )?.label
                                              }
                                            </Typography>
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <Box mr={2}>
                                                <Typography color="textSecondary">
                                                  {
                                                    catalogueAttributes?.filters.find(
                                                      f => f.key === filterKey
                                                    )?.pimInfo
                                                  }
                                                </Typography>
                                              </Box>
                                              <IconButton
                                                size="small"
                                                onClick={_ =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        }
                                        small
                                      />
                                    )
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {catalogueAttributes?.filters.filter(
                              f =>
                                !formik.values.options.filters.some(
                                  ff => ff === f.key
                                )
                            ).length ? (
                              <SelectField
                                key={formik.values.options.filters.length}
                                placeholder={t('common.add_filter')}
                                disableCloseOnSelect
                                onOptionChange={option => {
                                  arrayHelpers.push(
                                    catalogueAttributes?.filters.find(
                                      f => option?.value === f.key
                                    )?.key
                                  )
                                }}
                                options={catalogueAttributes?.filters
                                  .filter(
                                    f =>
                                      !formik.values.options.filters.some(
                                        ff => ff === f.key
                                      )
                                  )
                                  .map(ca => ({
                                    label: `${ca.label}${
                                      ca.pimInfo ? ` - ${ca.pimInfo}` : ''
                                    }`,
                                    value: ca.key,
                                  }))}
                              />
                            ) : null}
                          </DragDropContext>
                        )}
                      />
                    </CollapsibleOption>
                  </Box>
                ),
              },
            ]}
          />
        </form>
      </FormikProvider>
    </Box>
  )
}

export default ProductCatalogueDetailView
