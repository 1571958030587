import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { createRootReducer } from './reducers'
import { mainSaga } from './sagas'

export type RootState = ReturnType<typeof store.getState>

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const isDevelopment = process.env.NODE_ENV === 'development'
const composeEnhancers = isDevelopment
  ? composeWithDevTools({
      serialize: true,
      trace: true,
    })
  : compose

export const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
)

sagaMiddleware.run(mainSaga)
