import { AxiosError } from 'axios'
import httpCodes from 'http-status-codes'
import { useCallback, useMemo } from 'react'
import { createStateContext } from 'react-use'
import { apiMap } from '../api'
import { useFetch } from './useFetch'

const [useAuthState, AuthProvider] = createStateContext({
  isAuthenticated: false,
  isAuthorized: true,
})

export const useAuth = () => {
  const [{ isAuthenticated, isAuthorized }, setState] = useAuthState()
  const fetch = useFetch()

  const login = useCallback(() => {
    fetch({
      endpoint: apiMap.self,
      method: 'get',
    })
      .then(_ => setState({ isAuthenticated: true, isAuthorized }))
      .catch((error: AxiosError) => {
        if (error.response?.status === httpCodes.FORBIDDEN) {
          setState({ isAuthenticated: true, isAuthorized: false })
        }
      })
  }, [fetch, setState, isAuthorized])

  const logout = useCallback(() => {
    fetch({ endpoint: apiMap.logout, method: 'get' }).then(_ => {
      window.location.href = `${process.env.REACT_APP_AUTH_ENDPOINT}/logout?p=${process.env.REACT_APP_AUTH_POLICY}&post_logout_redirect_uri=${encodeURIComponent(process.env.REACT_APP_AUTH_REDIRECT_URI)}`
    })
  }, [fetch])

  const isApproved = useMemo(() => isAuthenticated && isAuthorized, [
    isAuthenticated,
    isAuthorized,
  ])

  return {
    login,
    logout,
    isAuthenticated,
    isAuthorized,
    isApproved,
  }
}

export { AuthProvider }
