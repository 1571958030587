import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    bottom: theme.spacing(3),
    left: '50%',
    position: 'fixed',
    transform: 'translateX(-50%)',
    zIndex: 1301,
  },
}))
