import axios, { AxiosError, AxiosResponse } from 'axios'
import httpCodes from 'http-status-codes'
import Cookies from 'js-cookie'
import qs from 'querystring'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { select } from 'typed-redux-saga'
import fetchActions from '../actions/fetchActions'
import snackActions from '../actions/snackActions'
import { getPool } from '../selectors/getPool'

export function* fetchSaga() {
  yield takeEvery(fetchActions.fetchAddAction, fetchAddWorker)
  yield takeLeading(fetchActions.fetchStartAction, fetchStartWorker)
  yield takeEvery(fetchActions.fetchErrorAction, fetchErrorWorker)
}

function* fetchAddWorker() {
  yield put(fetchActions.fetchStartAction())
}

function* fetchStartWorker() {
  while (true) {
    const pool = yield* select(getPool)
    if (pool.length === 0) break
    const action = pool[0]

    try {
      switch (action.payload.method) {
        case 'get':
          yield fetchGetWorker(action)
          break
        case 'post':
          yield fetchPostWorker(action)
          break
        case 'put':
          yield fetchPutWorker(action)
          break
        case 'delete':
          yield fetchDeleteWorker(action)
          break
      }
    } catch (error) {
      const reject = action.meta.reject

      yield put(fetchActions.fetchErrorAction(error as AxiosError, { reject }))
    }
  }
}

function* fetchGetWorker(
  action: ReturnType<typeof fetchActions.fetchAddAction>
) {
  const headers = { Authorization: `Bearer ${Cookies.get('id_token')}` }
  const charKey = action.meta.charKey
  const config = {
    ...action.payload.config,
    headers,
  }

  const response: AxiosResponse = yield call(
    axios.get,
    action.payload.endpoint,
    config
  )

  yield put(fetchActions.fetchEndAction({ response }, { charKey }))
  yield call(action.meta.resolve, response)
}

function* fetchPostWorker(
  action: ReturnType<typeof fetchActions.fetchAddAction>
) {
  const headers = { Authorization: `Bearer ${Cookies.get('id_token')}` }
  const charKey = action.meta.charKey
  const config = {
    ...action.payload.config,
    headers,
  }

  const response: AxiosResponse = yield call(
    axios.post,
    action.payload.endpoint,
    action.payload.body,
    config
  )

  yield put(fetchActions.fetchEndAction({ response }, { charKey }))
  yield call(action.meta.resolve, response)
}

function* fetchPutWorker(
  action: ReturnType<typeof fetchActions.fetchAddAction>
) {
  const headers = { Authorization: `Bearer ${Cookies.get('id_token')}` }
  const charKey = action.meta.charKey
  const config = {
    ...action.payload.config,
    headers,
  }

  const response: AxiosResponse = yield call(
    axios.put,
    action.payload.endpoint,
    action.payload.body,
    config
  )

  yield put(fetchActions.fetchEndAction({ response }, { charKey }))
  yield call(action.meta.resolve, response)
}

function* fetchDeleteWorker(
  action: ReturnType<typeof fetchActions.fetchAddAction>
) {
  const headers = { Authorization: `Bearer ${Cookies.get('id_token')}` }
  const charKey = action.meta.charKey
  const config = {
    ...action.payload.config,
    headers,
  }

  const response: AxiosResponse = yield call(
    axios.delete,
    action.payload.endpoint,
    config
  )

  yield put(fetchActions.fetchEndAction({ response }, { charKey }))
  yield call(action.meta.resolve, response)
}

function* fetchErrorWorker(
  action: ReturnType<typeof fetchActions.fetchErrorAction>
) {
  if (action.payload.response?.status === httpCodes.UNAUTHORIZED) {
    yield call(fetchAuthWorker)
  } else {
    yield call(action.meta.reject, action.payload)
    yield (action.payload.response?.status === httpCodes.BAD_REQUEST ||
      action.payload.response?.status === httpCodes.INTERNAL_SERVER_ERROR) &&
      put(
        snackActions.snackPushAction(
          { message: action.payload.response.data.message },
          { severity: 'error' }
        )
      )
  }
}

function* fetchAuthWorker() {
  const client_id = process.env.REACT_APP_AUTH_CLIENT_ID
  const code_challenge =
    'b0c179d7d3536c3fba6b77b5d807b74d6f4b4878d574229194b6095f21e2c65b'
  const p = process.env.REACT_APP_AUTH_POLICY
  const redirect_uri = process.env.REACT_APP_AUTH_REDIRECT_URI

  if (window.location.search.includes('code')) {
    const code = qs.parse(window.location.search.slice(1))['code']
    const params = {
      client_id,
      code,
      code_verifier: code_challenge,
      grant_type: 'authorization_code',
      p,
      redirect_uri,
    }

    try {
      const response: AxiosResponse = yield call(
        axios.post,
        `${process.env.REACT_APP_AUTH_ENDPOINT}/token`,
        null,
        { params }
      )
      Cookies.set('id_token', response.data.id_token)

      window.location.href = window.location.href.split('?')[0]
    } catch (error) {
      yield put(
        snackActions.snackPushAction(
          { message: (error as AxiosError).message },
          { severity: 'error' }
        )
      )
    }
  } else {
    const params = qs.stringify({
      client_id,
      code_challenge,
      code_challenge_method: 'plain',
      p,
      redirect_uri,
      response_type: 'code',
      scope: 'openid',
    })

    window.location.href = `${process.env.REACT_APP_AUTH_ENDPOINT}/authorize?${params}`
  }
}
