import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  signOutButton: {
    marginTop: theme.spacing(4),
  },
}))
