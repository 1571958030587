import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles/'
import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'
import { usePaletteType } from './usePaletteType'

export const useEnhancedTheme = () => {
  const { paletteType } = usePaletteType()

  useEffect(() => {
    Cookies.set('prefers_color_scheme', paletteType, { expires: 3650 })
  }, [paletteType])

  const defaultTheme = useMemo(() => createMuiTheme(), [])

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: paletteType,
          primary: {
            main: '#978859',
          },
          secondary: {
            dark: defaultTheme.palette.error.dark,
            main: defaultTheme.palette.error.main,
            light: defaultTheme.palette.error.light,
          },
        },
        typography: {
          fontFamily: '"Poppins", sans-serif',
          button: {
            textTransform: 'initial',
          },
          h4: {
            fontWeight: 500,
          },
        },
      }),
    [paletteType, defaultTheme.palette.error]
  )

  return theme
}
