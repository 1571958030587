import produce from 'immer'
import { ActionType, createReducer } from 'typesafe-actions'
import fetchActions from '../../actions/fetchActions'

type State = ReturnType<typeof fetchActions.fetchAddAction>[]

type Action = ActionType<typeof fetchActions>

const initialState: State = []

export default createReducer<State, Action>(initialState)
  .handleAction(fetchActions.fetchAddAction, (state, action) =>
    produce(state, draft => {
      draft.push(action)

      return draft
    })
  )
  .handleAction(
    [fetchActions.fetchEndAction, fetchActions.fetchErrorAction],
    state =>
      produce(state, draft => {
        draft.shift()

        return draft
      })
  )
