import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FC, ReactNode } from 'react'
import { useStyles } from './styles'

type Props = {
  title: string
  defaultExpanded?: boolean
  disabled?: boolean
  subTitle?: ReactNode
  withError?: boolean
  ''?: string
}

const CollapsibleOption: FC<Props> = ({
  title,
  subTitle,
  disabled,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Accordion
      className={classes.root}
      defaultExpanded={props.defaultExpanded}
      disabled={disabled}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={1}
        >
          <Typography
            className={classes.title}
            color={props.withError ? 'error' : undefined}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography className={classes.subtitle} color="textSecondary">
              {subTitle}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width={1}>{props.children}</Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default CollapsibleOption
