import { goBack, push, replace } from 'connected-react-router'
import { useDispatch } from 'react-redux'

export const useRouter = () => {
  const dispatch = useDispatch()

  return {
    goBack: () => dispatch(goBack()),
    push: (...args: Parameters<typeof replace>) => dispatch(push(...args)),
    replace: (...args: Parameters<typeof replace>) =>
      dispatch(replace(...args)),
  }
}
