import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { FC } from 'react'
import { useStyles } from './styles'

type Props = Partial<{
  noMarginTop: boolean
}>

const FieldGroupTitle: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} marginTop={props.noMarginTop ? 0 : 4}>
      <Typography
        className={classes.title}
        color="textSecondary"
        variant="body2"
      >
        {children}
      </Typography>
    </Box>
  )
}

export default FieldGroupTitle
