import { Color } from '@material-ui/lab/Alert'
import { createAction } from 'typesafe-actions'

type SnackSeverity = Color

type SnackOptions = {
  autoHideDuration?: number
}

export type SnackPushPayload = {
  readonly message: string
}

export type SnackPushMeta = Partial<{
  readonly severity: SnackSeverity
  readonly options: SnackOptions
}>

const snackPushAction = createAction('@@snack/PUSH')<
  SnackPushPayload,
  SnackPushMeta
>()

const snackShiftAction = createAction('@@snack/SHIFT')()

export default { snackPushAction, snackShiftAction } as const
