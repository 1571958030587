export const generateCharKey = (length: number, old?: string): string => {
  let key = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i <= length; i++) {
    key += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  if (key === old) {
    return generateCharKey(length, key)
  }

  return key
}
