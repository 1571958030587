import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { FC } from 'react'
import { useStyles } from './styles'

type Props = Partial<{}>

const FallbackSpinner: FC<Props> = props => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CircularProgress />
    </Box>
  )
}

export default FallbackSpinner
