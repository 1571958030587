import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import snackActions, {
  SnackPushMeta,
  SnackPushPayload,
} from '../store/actions/snackActions'

export const usePushSnack = () => {
  const dispatch = useDispatch()

  return useCallback(
    (payload: SnackPushPayload, meta: SnackPushMeta) => {
      dispatch(snackActions.snackPushAction(payload, meta))
    },
    [dispatch]
  )
}
