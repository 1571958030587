import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/CloseRounded'
import MenuIcon from '@material-ui/icons/MenuRounded'
import { FC } from 'react'
import Fab from '../../components/Fab'
import { useDrawer } from '../../core/hooks/useDrawer'

type Props = Partial<{}>

const DrawerFabLayout: FC<Props> = props => {
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const [{ isOpen }, { hide, show }] = useDrawer()

  return isXsDown ? (
    <Fab color="primary" onClick={isOpen ? hide : show}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Fab>
  ) : null
}

export default DrawerFabLayout
