import { ReactText, useCallback } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { apiMap, ApiMapKey } from '../api'
import { KeyValuePair } from '../tools/utilityTypes'
import { useCharKey } from './useCharKey'
import { useFetch } from './useFetch'
import { useResource } from './useResource'
import qs from 'querystring'

type Args = {
  id?: ReactText
  apiKey: ApiMapKey
  params?: KeyValuePair
  once?: boolean
  avoid?: boolean
}

export const useApiData = <T>({
  id,
  apiKey,
  params,
  once,
  avoid,
}: Args): T | undefined => {
  const fetch = useFetch()
  const url = `${apiMap[apiKey]}${id ? `/${id}` : ''}`
  const data = useResource<T>({ url, params, method: 'get', once })
  const { charKey } = useCharKey()

  const fetchData = useCallback(() => {
    fetch<T>({
      endpoint: url,
      config: { params },
      method: 'get',
    }).catch(_ => {})
  }, [params, url, fetch])

  const handleChanges = useCallback(() => {
    if (data || avoid) return

    fetchData()
  }, [data, avoid, fetchData])

  useMount(handleChanges)

  useUpdateEffect(handleChanges, [apiKey, id, qs.stringify(params), charKey])

  return avoid ? undefined : data
}
