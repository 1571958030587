import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded'
import { FC, ReactElement } from 'react'
import { useRouter } from '../../core/hooks/useRouter'
import { useStyles } from './styles'

type Props = Partial<{
  title: string
  subtitle: string
  actionRender: ReactElement
  withBackButton: boolean
  testAttrNamespace: string
}>

const Title: FC<Props> = props => {
  const { goBack } = useRouter()

  const classes = useStyles()

  return (
    <Box className={classes.root} mb={5}>
      <Box display="flex" alignItems="start">
        {props.withBackButton && (
          <IconButton
            className={classes.goBackButton}
            color="primary"
            size="small"
            onClick={goBack}
            title="Go back"
          >
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        )}
        <Box>
          <Typography variant="h4" component="h1">
            {props.title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" component="h2">
            {props.subtitle}
          </Typography>
        </Box>
      </Box>
      <Box>{props.actionRender}</Box>
    </Box>
  )
}

export default Title
