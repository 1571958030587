import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MeIcon from '@material-ui/icons/AccountCircle'
import DarkIcon from '@material-ui/icons/Brightness4Rounded'
import LightIcon from '@material-ui/icons/Brightness7Rounded'
import RefreshIcon from '@material-ui/icons/RefreshRounded'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import NavItem from '../../components/NavItem'
import BicepsSvg from '../../components/Svg/BicepsSvg'
import ComponentSvg from '../../components/Svg/ComponentSvg'
import CompositionSvg from '../../components/Svg/CompositionSvg'
import DashboardSvg from '../../components/Svg/DashboardSvg'
import ShowcaseSvg from '../../components/Svg/ShowcaseSvg'
import { useApiUpdate } from '../../core/hooks/useApiUpdate'
import { useBrand } from '../../core/hooks/useBrand'
import { useCharKey } from '../../core/hooks/useCharKey'
import { useDrawer } from '../../core/hooks/useDrawer'
import { useIsRichemont } from '../../core/hooks/useIsRichemont'
import { useLoadingState } from '../../core/hooks/useLoadingState'
import { usePaletteType } from '../../core/hooks/usePaletteType'
import { useStyles } from './styles'

const DrawerLayout: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { togglePaletteType, hasDarkTheme } = usePaletteType()
  const theme = useTheme()
  const { selfBrand, allBrands } = useBrand()
  const { isRichemont } = useIsRichemont()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const loading = useLoadingState()
  const location = useLocation()
  const history = useHistory()
  const [{ isOpen }, { hide, show }] = useDrawer()
  const { generateCharKey } = useCharKey()
  const { t } = useTranslation()
  const updateSelf = useApiUpdate({ apiKey: 'self' })

  const brandHandleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const brandHandleClose = () => {
    setAnchorEl(null)
  }

  const onBrandChange = (brand: number) => {
    updateSelf({ brand }).then(() => {
      history.push(`/${location.pathname.split('/')[1]}`)
      document.location.reload()
    })
  }

  useUpdateEffect(() => {
    setTimeout(isSmUp ? show : hide, 200)
  }, [isSmUp])

  useUpdateEffect(() => {
    isOpen && !isSmUp && hide()
  }, [location.key])

  const classes = useStyles()
  return (
    <Drawer
      anchor="left"
      className={classes.root}
      open={isOpen}
      variant={isSmUp ? 'persistent' : 'temporary'}
      PaperProps={{
        className: clsx([classes.drawerPaper]),
      }}
      {...{
        ...(!isSmUp && {
          onBackdropClick: hide,
        }),
      }}
    >
      <Box className={classes.logoNavLinkBox}>
        <NavLink className={classes.logoNavLink} draggable={false} exact to="/">
          <BicepsSvg
            armColor={theme.palette.common.white}
            handColor={theme.palette.primary.main}
          />
        </NavLink>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.navListBox}>
        <Box>
          <List className={classes.navList}>
            <NavItem
              exact
              to="/"
              title={t('common.dashboard')}
              iconRender={<DashboardSvg />}
            />
            <NavItem
              iconRender={<ShowcaseSvg />}
              to="/virtual-showcases"
              title={t('common.virtual_showcases')}
            />
            <NavItem
              to="/compositions"
              title={t('common.compositions')}
              iconRender={<CompositionSvg />}
            />
            <NavItem
              to="/components"
              title={t('common.components')}
              iconRender={<ComponentSvg />}
            />
          </List>
          <Divider className={classes.divider} />
        </Box>
        <Box className={classes.miscBox}>
          <Divider className={classes.divider} />
          <List className={classes.navList}>
            {loading ? (
              <CircularProgress
                className={classes.circularProgress}
                size={16}
                thickness={6}
              />
            ) : (
              <NavItem
                icon={RefreshIcon}
                title={t('common.refresh')}
                onClick={generateCharKey}
              />
            )}
            <NavItem
              icon={hasDarkTheme ? LightIcon : DarkIcon}
              title={t('layouts.drawer.toggle_theme')}
              onClick={togglePaletteType}
            />
            {isRichemont && (
              <NavItem
                iconRender={
                  <Typography className={classes.trigram} variant="subtitle1">
                    {selfBrand?.trigram}
                  </Typography>
                }
                title={t('layouts.drawer.swap_brand')}
                onClick={brandHandleClick}
              />
            )}
            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              classes={{ paper: classes.trigramPaper }}
              keepMounted
              open={Boolean(anchorEl)}
              anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
              onClose={brandHandleClose}
              style={{ marginLeft: 234, pointerEvents: 'none' }}
            >
              {allBrands?.map(brand => (
                <MenuItem
                  disabled={!brand.appVersion}
                  key={brand.id}
                  onClick={() => {
                    onBrandChange(brand.id)
                    brandHandleClose()
                  }}
                >
                  <Box display="flex" justifyContent="space-between" width={1}>
                    <span style={{ marginRight: 20 }}>
                      {brand.appVersion || 'N/A'}
                    </span>

                    {brand.appVersion && (
                      <span>DMS {brand.dms21 ? '2.1' : '2.0'}</span>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              classes={{ paper: classes.trigramPaper }}
              keepMounted
              open={Boolean(anchorEl)}
              anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
              onClose={brandHandleClose}
            >
              {allBrands?.map(brand => (
                <MenuItem
                  key={brand.id}
                  disabled={!brand.appVersion}
                  onClick={() => {
                    onBrandChange(brand.id)
                    brandHandleClose()
                  }}
                >
                  <Box display="flex" justifyContent="space-between" width={1}>
                    <span style={{ marginRight: 16 }}>{brand.name}</span>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
            <NavItem icon={MeIcon} to="/profile" title={t('common.profile')} />
          </List>
        </Box>
      </Box>
    </Drawer>
  )
}

export default DrawerLayout
