import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => {
  const hoverBackground = fade(theme.palette.common.white, 0.1)

  return {
    root: {
      margin: theme.spacing(1),
    },
    tooltip: {
      ...theme.typography.body2,
      marginLeft: theme.spacing(3),
      padding: theme.spacing(1, 2),
    },
    listItem: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      margin: theme.spacing(0.5, 0),
      '&:hover': {
        background: hoverBackground,
      },
      '&:focus': {
        background: hoverBackground,
      },
    },
    largePadding: {
      padding: theme.spacing(1.5),
    },
    active: {
      color: theme.palette.primary.main,
    },
  }
})
