import { Method } from 'axios'
import { useSelector } from 'react-redux'
import { getResource } from '../store/selectors/getResource'
import { KeyValuePair } from '../tools/utilityTypes'
import { useCharKey } from './useCharKey'

type Args = {
  once?: boolean
  method: Method
  params?: KeyValuePair
  url: string
}

export const useResource = <T>(args: Args): T | undefined => {
  const { charKey } = useCharKey()

  return useSelector(
    getResource({
      ...args,
      charKey,
    })
  )
}
