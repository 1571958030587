import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { FC } from 'react'
import { Switch } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const SwitchWrapper: FC = ({ children }) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box ml={isSmUp ? 10 : 0} pt={6} pb={isSmUp ? 0 : 13}>
      <Container>
        <Switch>{children}</Switch>
      </Container>
    </Box>
  )
}

export default SwitchWrapper
