import { useCallback, useMemo } from 'react'
import { createStateContext } from 'react-use'
import { generateCharKey as generate } from '../tools/generateCharKey'

const [useCharKeysState, CharKeysProvider] = createStateContext<string[]>([
  generate(4),
])

const useCharKey = () => {
  const [allCharKeys, setAllCharKeys] = useCharKeysState()

  const charKey = useMemo(
    () => allCharKeys[allCharKeys.length - 1],
    [allCharKeys]
  )

  const generateCharKey = useCallback(() => {
    setAllCharKeys([...allCharKeys, generate(4, charKey)])
  }, [charKey, allCharKeys, setAllCharKeys])

  return { charKey, allCharKeys, generateCharKey }
}

export { useCharKey, CharKeysProvider }
