import { FC, Suspense } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import FallbackSpinner from '../components/FallbackSpinner'

const RouteWrapper: FC<RouteProps> = ({ children, ...props }) => {
  return (
    <Route {...props}>
      <Suspense fallback={<FallbackSpinner />}>{children}</Suspense>
    </Route>
  )
}

export default RouteWrapper
