import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import { FC } from 'react'
import { useMount } from 'react-use'
import Button from '../../components/Button'
import { useAuth } from '../../core/hooks/useAuth'
import { useStyles } from './styles'

type Props = Partial<{}>

const LoginView: FC<Props> = props => {
  const { login, logout, isAuthorized } = useAuth()

  useMount(() => {
    login()
  })

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {isAuthorized ? (
        <CircularProgress />
      ) : (
        <Box textAlign="center">
          <Typography variant="h3">Access Denied</Typography>
          <Typography color="textSecondary">
            Please contact your BiCePS brand admin to manage access rights.
          </Typography>
          <Button
            className={classes.signOutButton}
            color="primary"
            disableElevation
            size="large"
            endIcon={<ExitIcon />}
            onClick={logout}
            variant="contained"
          >
            Sign out
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default LoginView
