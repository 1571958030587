import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import snackActions from '../store/actions/snackActions'

export const useShiftSnack = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(snackActions.snackShiftAction())
  }, [dispatch])
}
