import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import { FC, MouseEventHandler, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { useStyles } from './styles'

type Props = {
  disabled?: boolean
  exact?: boolean
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  iconRender?: ReactNode
  title: string
  to?: string
  onClick?: MouseEventHandler<HTMLElement> | undefined
}

const NavItem: FC<Props> = ({ icon: Icon, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={props.title}
        placement="right"
        arrow
      >
        {props.to ? (
          <ListItem
            activeClassName={classes.active}
            button
            className={clsx([classes.listItem, Icon && classes.largePadding])}
            component={NavLink}
            disabled={props.disabled}
            draggable={false}
            exact={props.exact}
            to={props.to}
          >
            {props.iconRender && props.iconRender}
            {Icon && <Icon />}
          </ListItem>
        ) : (
          <ListItem
            button
            className={clsx([classes.listItem, Icon && classes.largePadding])}
            disabled={props.disabled}
            onClick={props.onClick}
          >
            {props.iconRender && props.iconRender}
            {Icon && <Icon />}
          </ListItem>
        )}
      </Tooltip>
    </Box>
  )
}

export default NavItem
