import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import i18next from 'i18next'
import { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { Provider as StoreProvider } from 'react-redux'
import { AuthProvider } from './core/hooks/useAuth'
import { CharKeysProvider } from './core/hooks/useCharKey'
import { DrawerProvider } from './core/hooks/useDrawer'
import { useEnhancedTheme } from './core/hooks/useEnhancedTheme'
import { PaletteTypeProvider } from './core/hooks/usePaletteType'
import { i18nInitOptions } from './core/i18n'
import { store } from './core/store'
import LogoLayout from './layouts/LogoLayout'
import SnackLayout from './layouts/SnackLayout'
import Router from './routes/Router'

i18next.use(initReactI18next).init(i18nInitOptions)

const App: FC = () => {
  const theme = useEnhancedTheme()

  return (
    <StoreProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <DndProvider backend={HTML5Backend}>
          <AuthProvider>
            <CharKeysProvider>
              <DrawerProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <LogoLayout />
                  <SnackLayout />
                  <Router />
                </ThemeProvider>
              </DrawerProvider>
            </CharKeysProvider>
          </AuthProvider>
        </DndProvider>
      </I18nextProvider>
    </StoreProvider>
  )
}

const Provider: FC = () => (
  <PaletteTypeProvider>
    <App />
  </PaletteTypeProvider>
)

export default Provider
