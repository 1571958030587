export default function Svg() {
  return (
    <svg height={32} viewBox="0 0 512 512" width={32}>
      <path
        d="M481.8,0H30.2C13.5,0,0,13.5,0,30.2V111h512V30.2C512,13.5,498.5,0,481.8,0z M56,71.5c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S64.8,71.5,56,71.5z M108,71.5c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S116.8,71.5,108,71.5z M160,71.5c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S168.8,71.5,160,71.5z"
        fill="currentColor"
      />
      <path
        d="M213,141H0v340.8C0,498.5,13.5,512,30.2,512H241V169C241,153.5,228.5,141,213,141z"
        fill="currentColor"
      />
      <path
        d="M512,311.5V141H299c-15.5,0-28,12.5-28,28v114.5c0,15.5,12.5,28,28,28H512z"
        fill="currentColor"
      />
      <path
        d="M271,369.5V512h210.8c16.7,0,30.2-13.5,30.2-30.2V341.5H299C283.5,341.5,271,354,271,369.5z"
        fill="currentColor"
      />
    </svg>
  )
}
