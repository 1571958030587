import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import CreateIcon from '@material-ui/icons/AddRounded'
import EditIcon from '@material-ui/icons/EditRounded'
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete'
import { FormikErrors, FormikValues } from 'formik'
import { FC, ReactNode, ReactText, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from '../../core/hooks/useRouter'
import { Nullable } from '../../core/tools/utilityTypes'
import { useStyles } from './styles'

export type Option = {
  label: string
  value: ReactText
  groupBy?: string
}

type Props = Pick<TextFieldProps, 'onBlur'> &
  Partial<{
    autoFocus: boolean
    disabled: boolean
    disableCloseOnSelect: boolean
    error: string
    unclearable: boolean
    label: string
    name: string
    noMargin: boolean
    openOnFocus: boolean
    touched: boolean
    options: Option[]
    groupBy: (option: Option) => string | undefined
    renderOption: (
      option: Option,
      state: AutocompleteRenderOptionState
    ) => ReactNode
    onOptionChange: (option: Nullable<Option>) => void
    setValueFunc: (
      field: string,
      value: unknown,
      shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<FormikValues>> | Promise<void>
    placeholder: string
    value: Nullable<Option['value']>
    withNoValue: boolean
    redirection: {
      pathname: string
    }
    required: boolean
  }>

const SelectField: FC<Props> = props => {
  const { t } = useTranslation()
  const { push } = useRouter()
  const [open, setOpen] = useState(false)
  const loading = open && !props.options
  const inputRef = useRef<HTMLDivElement>(null)

  const hasError = useMemo(
    () => props.touched && !!props.error,
    [props.touched, props.error]
  )

  const classes = useStyles({ unclearable: props.unclearable })

  return (
    <Autocomplete<Option>
      open={open}
      inputValue={props.withNoValue ? '' : undefined}
      openOnFocus={props.openOnFocus}
      disableCloseOnSelect={props.disableCloseOnSelect}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disabled={props.disabled}
      loading={loading}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, selectedOption) =>
        option.value === selectedOption.value
      }
      groupBy={option => (props.groupBy && props.groupBy(option)) || ''}
      options={props.options || []}
      onChange={(_, option) => {
        if (props.unclearable && option === null) {
          return
        }

        props.setValueFunc &&
          props.setValueFunc(props.name as string, option?.value || null)

        props.onOptionChange && props.onOptionChange(option)
      }}
      renderOption={props.renderOption}
      renderInput={params => (
        <Box className={classes.root} display="flex">
          <TextField
            {...params}
            inputRef={inputRef}
            autoFocus={props.autoFocus}
            error={hasError}
            fullWidth
            helperText={props.touched && props.error}
            label={props.label}
            margin="normal"
            name={props.name}
            onBlur={props.onBlur}
            size="small"
            style={{
              height: hasError ? 63 : 40,
              margin: props.noMargin ? 0 : undefined,
            }}
            placeholder={props.placeholder}
            required={props.required}
            variant="outlined"
            InputLabelProps={
              props.value ? { shrink: true } : params.InputLabelProps
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
            }}
          />
          {props.redirection && (
            <Box mt={2.625} ml={1} display="flex">
              <IconButton
                disabled={props.disabled || !props.value}
                size="small"
                title={t('common.edit')}
                onClick={_ => {
                  push({
                    pathname: `${props.redirection?.pathname}/${props.value}`,
                  })
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                className={classes.createButton}
                disabled={props.disabled}
                size="small"
                title={t('common.create')}
                onClick={_ => {
                  push({ pathname: `${props.redirection?.pathname}/create` })
                }}
              >
                <CreateIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      value={
        (props.value &&
          props.options?.find(option => option.value === props.value)) ||
        null
      }
    />
  )
}

export default SelectField
