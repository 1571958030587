import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import fetchActions, { FetchAddPayload } from '../store/actions/fetchActions'
import { useCharKey } from './useCharKey'

export const useFetch = () => {
  const { charKey } = useCharKey()
  const dispatch = useDispatch()

  return useCallback(
    <T>(payload: FetchAddPayload) => {
      const promise = new Promise<AxiosResponse<T>>((resolve, reject) => {
        dispatch(
          fetchActions.fetchAddAction(payload, {
            charKey,
            resolve,
            reject,
          })
        )
      })

      return promise
    },
    [charKey, dispatch]
  )
}
