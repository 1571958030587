import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { createAction } from 'typesafe-actions'

export type FetchAddPayload = {
  body?: unknown
  config?: Omit<AxiosRequestConfig, 'headers'>
  endpoint: string
  noAuth?: boolean
  method: Method
}

export type FetchAddMeta = {
  charKey: string
  resolve: (response: AxiosResponse) => void
  reject: (reason?: unknown) => void
}

type FetchEndPayload = { response: AxiosResponse }

type FetchEndMeta = { charKey: string }

type FetchErrorPayload = AxiosError

type FetchErrorMeta = {
  reject: (error: AxiosError) => void
}

const fetchStartAction = createAction('@@fetch/START')()

const fetchAddAction = createAction('@@fetch/ADD')<
  FetchAddPayload,
  FetchAddMeta
>()

const fetchEndAction = createAction('@@fetch/END')<
  FetchEndPayload,
  FetchEndMeta
>()

const fetchErrorAction = createAction('@@fetch/ERROR')<
  FetchErrorPayload,
  FetchErrorMeta
>()

const fetchCancelAction = createAction('@@fetch/CANCEL')()

export default {
  fetchAddAction,
  fetchStartAction,
  fetchEndAction,
  fetchErrorAction,
  fetchCancelAction,
} as const
