import produce from 'immer'
import { createReducerContext } from 'react-use'
import { ActionType, createAction, createReducer } from 'typesafe-actions'

type State = {
  isOpen: boolean
}

const initialState: State = {
  isOpen: false,
}

const actions = {
  hide: createAction('hide')(),
  show: createAction('show')(),
}

const reducer = createReducer<State, ActionType<typeof actions>>(initialState)
  .handleAction(actions.hide, (state, _) =>
    produce(state, draft => {
      draft.isOpen = false

      return draft
    })
  )
  .handleAction(actions.show, (state, _) =>
    produce(state, draft => {
      draft.isOpen = true

      return draft
    })
  )

const [useDrawerReducer, DrawerProvider] = createReducerContext(
  reducer,
  initialState
)

export const useDrawer = () => {
  const [state, dispatch] = useDrawerReducer()

  const drawerDispatch = {
    hide: () => dispatch({ type: 'hide' }),
    show: () => dispatch({ type: 'show' }),
  }

  return [state, drawerDispatch] as const
}

export { DrawerProvider }
