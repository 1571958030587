import { makeStyles } from '@material-ui/core/styles'

type Props = {
  small?: boolean
}

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  handle: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  clear: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  paper: ({ small }: Props) => ({
    borderWidth: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing(small ? 0.5 : 2),
  }),
}))
