import MuiFab, { FabProps } from '@material-ui/core/Fab'
import clsx from 'clsx'
import { FC } from 'react'
import { useStyles } from './styles'

type Props = FabProps

const Fab: FC<Props> = props => {
  const classes = useStyles()

  return <MuiFab className={clsx([props.className, classes.root])} {...props} />
}

export default Fab
