import { apiMap, ApiMapKey } from '../api'
import { useFetch } from './useFetch'

type Args = {
  apiKey: ApiMapKey
  id?: number
}

export const useApiUpdate = ({ apiKey, id }: Args) => {
  const endpoint = `${apiMap[apiKey]}${id ? `/${id}` : ''}`
  const fetch = useFetch()

  return <T>(body: unknown) => fetch<T>({ endpoint, method: 'put', body })
}
