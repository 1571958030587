import { apiMap, ApiMapKey } from '../api'
import { KeyValuePair } from '../tools/utilityTypes'
import { useFetch } from './useFetch'

type Args = {
  apiKey: ApiMapKey
  params?: KeyValuePair
}

export const useApiCreate = ({ apiKey, params }: Args) => {
  const endpoint = apiMap[apiKey]
  const fetch = useFetch()

  return <T>(body: unknown) =>
    fetch<T>({ endpoint, method: 'post', body, config: { params } })
}
