import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from './styles'

type Props = ButtonProps &
  Partial<{
    to: string
    danger: boolean
    loading: boolean
  }>

const Button: FC<Props> = ({ children, color, danger, loading, ...props }) => {
  const classes = useStyles()

  return (
    <MuiButton
      variant="outlined"
      color={danger ? 'secondary' : color}
      {...props}
      className={clsx(props.className, classes.root)}
      component={props.to ? Link : 'button'}
      disabled={loading || props.disabled}
      draggable={false}
      to={props.to}
      endIcon={
        loading ? (
          <CircularProgress color="inherit" size={16} thickness={6} />
        ) : (
          props.endIcon
        )
      }
    >
      {children}
    </MuiButton>
  )
}

export default Button
