import produce from 'immer'
import { ActionType, createReducer } from 'typesafe-actions'
import snackActions, {
  SnackPushMeta,
  SnackPushPayload,
} from '../../actions/snackActions'

type State = { payload: SnackPushPayload; meta: SnackPushMeta }[]
type Action = ActionType<typeof snackActions>

const initialState: State = []

export default createReducer<State, Action>(initialState)
  .handleAction(snackActions.snackPushAction, (state, action) =>
    produce(state, draft => {
      const payload = action.payload
      const meta = action.meta

      draft.push({ payload, meta })
    })
  )
  .handleAction(snackActions.snackShiftAction, (state, _) =>
    produce(state, draft => {
      draft.shift()
    })
  )
