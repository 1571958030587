import { FC } from 'react'

type Props = Partial<{
  armColor: string
  className: string
  handColor: string
}>

const Svg: FC<Props> = props => (
  <svg viewBox="0 0 512 512" className={props.className}>
    <ellipse
      cx="421.61"
      cy="67.38"
      rx="67.93"
      ry="67.38"
      fill={props.handColor}
    />
    <path
      d="M512,125.23c0-8-11-9.91-13.64-2.38-15.87,46-59.19,87.87-104.57,114.1C336.7,270,255.6,289.58,255.6,343.07c0-84.89-102.74-200.2-242.76-207.08A12.35,12.35,0,0,0,0,149.09c8.74,167.29,177.8,311.27,236.05,356.09l.37.28a31.39,31.39,0,0,0,38.33,0C275.34,505.05,512,297.39,512,125.77Z"
      fill={props.armColor}
    />
  </svg>
)

export default Svg
