import { grey } from '@material-ui/core/colors'
import { useTheme } from '@material-ui/core/styles'
import { FC, useMemo } from 'react'
import BicepsSvg from '../../components/Svg/BicepsSvg'
import { useStyles } from './styles'

type Props = Partial<{}>

const LogoLayout: FC<Props> = props => {
  const theme = useTheme()

  const color = useMemo(
    () => (theme.palette.type === 'light' ? grey[300] : grey[800]),
    [theme]
  )

  const classes = useStyles()

  return (
    <BicepsSvg className={classes.root} armColor={color} handColor={color} />
  )
}

export default LogoLayout
